export const TAB_NAMES = {
    GHG: "ghg",
    SUMMARY: "summary",
    FORECASTS: "forecasts",
    DETAILED_RESULTS: "detailed-results",
    EMPLOYEE_HIGHLIGHTS: "employee-highlights",
    RECOMMEND: "recommend",
    HELP_SECTION: "help-section",
    CUSTOMER_TRAVEL: "customer-travel",
    DENTISTRY: "dentistry",
    LOCATIONS: "locations",
    PURCHASES: "purchases",
    ASSESSMENT_SURVEY: "assessment-survey",
    PROGRESS: "progress",
}