import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import firebaseInstance from 'src/adapters/common-set-up/firebase';
import { CUSTOMER_TRAVEL_TAB, DENTISTRY_TAB, EMPLOYEE_HIGHLIGHTS_TAB, GHG_TAB, LOCATIONS_TAB, PROGRESS_TAB, PURCHASES_TAB, SUMMARY_TABS, SURVEY_TAB } from "src/constants/summaryTabs";
import { selectAssessmentSurvey, selectEmployees, selectEsgSurveyOpen, selectLog, selectOrgData, selectOrgId, selectPeriod, selectSector, selectSites } from "src/store/projectSelectors";

const useSummaryTabs = () => {
    const orgId = useSelector(selectOrgId)
    const periodId = useSelector(selectPeriod)

    const orgData = useSelector(selectOrgData)

    const { hideResults, showGhg } = orgData

    let tabs = SUMMARY_TABS

    /***** EMPLOYEE SURVEY ******/

    const employees = useSelector(selectEmployees)
    const hasEmployeeSurvey = employees?.filter(e => e.isFromSurvey).length > 0

    /******* DENTISTRY ******/

    const sector = useSelector(selectSector);

    const sectorIsDentistry = sector === "Dentistry";

    /******* ASSESSMENT SURVEY ******/

    const hasSurveyEntries = useSelector(selectAssessmentSurvey)?.length > 0
    const esgSurveyOpen = useSelector(selectEsgSurveyOpen)


    /******* PROGRESS ******/

    const [hasPastPeriods, setHasPastPeriods] = useState(false)
    useEffect(() => {
        if (!orgId || !periodId) return
        if (!firebaseInstance) return
        async function checkPastPeriods() {
            const db = firebaseInstance.firestore()

            const periodsSnapshot = await db
                .collection("organisations")
                .doc(orgId)
                .collection("periods")
                .get()

            const periods = periodsSnapshot.docs.map(doc => doc.data()).sort((a, b) => a.startDate - b.startDate)
            const currentPeriodIdx = periods.findIndex(period => period.id === periodId)
            const hasPastPeriods = currentPeriodIdx > 0
            setHasPastPeriods(hasPastPeriods)

        }
        checkPastPeriods()
    }, [orgId, periodId, firebaseInstance])


    /******* LOCATIONS ******/

    const sites = useSelector(selectSites)
    const hasSites = sites?.length > 0


    /******* PURCHASES ******/

    const mainLog = useSelector(selectLog)



    const hasPurchaseEmissions = !!mainLog?.log?.find(record => record.subcategory === "CATEGORY TOTAL" && record.emissions)


    /******* CUSTOMER TRAVEL ******/

    const hasCustomerTravel = sites?.filter(site => site.travel && Object.keys(site.travel).length)?.length

    /******* RESULT ******/

    if (showGhg) {
        tabs = [...tabs, GHG_TAB]
    }

    if (hasEmployeeSurvey) {
        tabs = [...tabs, EMPLOYEE_HIGHLIGHTS_TAB]
    }

    if (hasSites) {
        tabs = [...tabs, LOCATIONS_TAB]
    }

    if (sectorIsDentistry) {
        tabs = [...tabs, DENTISTRY_TAB]
    }

    if (hasPurchaseEmissions) {
        tabs = [...tabs, PURCHASES_TAB]
    }

    if (hasCustomerTravel) {
        tabs = [...tabs, CUSTOMER_TRAVEL_TAB]
    }

    if (hasSurveyEntries && esgSurveyOpen) {
        tabs = [...tabs, SURVEY_TAB]
    }

    if (hasPastPeriods) {
        tabs = [...tabs, PROGRESS_TAB]
    }

    if (hideResults) {
        tabs = []
    }

    return { tabs }
}

export default useSummaryTabs